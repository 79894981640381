import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 489.000000 318.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,318.000000) scale(0.100000,-0.100000)">



<path d="M2083 2587 c-113 -157 -149 -345 -103 -526 22 -88 39 -126 88 -198
34 -52 62 -68 62 -38 0 8 -4 15 -9 15 -5 0 -12 8 -15 18 -4 10 -14 27 -24 38
-27 29 -60 103 -73 164 -6 30 -14 58 -18 62 -9 10 5 196 19 251 13 50 76 178
101 206 21 22 24 37 10 45 -4 3 -22 -14 -38 -37z"/>
<path d="M2870 2597 c0 -7 16 -36 35 -64 19 -29 35 -57 35 -63 0 -6 5 -8 12
-4 6 4 8 3 5 -3 -3 -5 3 -38 15 -74 19 -60 41 -262 27 -253 -4 2 -10 -20 -13
-49 -8 -58 -64 -185 -102 -230 -18 -22 -22 -33 -14 -41 12 -12 37 14 67 74 10
19 20 37 23 40 27 27 58 145 66 250 5 67 3 105 -11 161 -17 73 -96 247 -118
261 -18 11 -27 10 -27 -5z"/>
<path d="M2201 2474 c-12 -15 -21 -33 -21 -40 0 -8 -4 -14 -10 -14 -5 0 -13
-12 -16 -27 -4 -16 -10 -32 -14 -38 -18 -26 -30 -135 -21 -193 16 -103 40
-159 98 -231 2 -3 8 0 14 6 7 7 1 24 -20 55 -54 80 -77 225 -50 323 14 52 59
145 70 145 5 0 9 9 9 20 0 27 -15 25 -39 -6z"/>
<path d="M2750 2486 c0 -3 15 -34 34 -68 19 -35 39 -79 45 -99 12 -37 20 -190
10 -184 -3 2 -11 -18 -18 -45 -7 -28 -24 -66 -37 -85 -24 -36 -31 -65 -15 -65
13 0 32 26 56 78 34 73 48 141 46 222 -3 83 -69 241 -104 248 -10 2 -17 1 -17
-2z"/>
<path d="M2311 2344 c-19 -28 -25 -52 -29 -114 -4 -68 -1 -85 21 -134 15 -33
32 -56 41 -56 22 0 20 11 -9 66 -32 61 -34 151 -5 209 35 69 22 89 -19 29z"/>
<path d="M2640 2367 c0 -8 8 -29 17 -48 9 -19 19 -62 21 -96 4 -53 0 -70 -21
-112 -24 -45 -26 -61 -6 -61 21 0 52 88 57 156 4 64 2 77 -23 123 -28 50 -45
65 -45 38z"/>
<path d="M2457 2263 c-28 -31 -30 -66 -7 -96 39 -49 100 -24 102 42 2 59 -59
93 -95 54z m40 -30 c19 -17 21 -43 2 -43 -4 0 -5 8 -2 17 5 12 2 15 -8 11 -10
-4 -15 1 -16 13 -2 23 -1 23 24 2z"/>
<path d="M2463 2010 c-13 -5 -25 -21 -29 -37 -3 -15 -14 -55 -25 -87 -10 -33
-19 -69 -19 -80 0 -12 -12 -59 -25 -105 -39 -132 -51 -121 135 -121 l161 0 -7
38 c-3 20 -20 89 -37 152 -16 63 -36 142 -43 174 -7 32 -19 63 -26 67 -17 11
-57 10 -85 -1z m4 -142 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m114 -34 c5 -15 8 -29 5 -31 -2 -2 -8 10 -14 26 -13 38 -5 41 9 5z m-121 -4
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m118
-95 c0 -16 -2 -15 -8 5 -4 14 -8 34 -8 45 0 16 2 15 8 -5 4 -14 8 -34 8 -45z
m-3 -45 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-188 -72 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1606 1334 c-9 -8 -16 -25 -16 -36 0 -20 0 -20 18 -1 9 10 26 26 37
36 19 17 19 17 -2 17 -12 0 -29 -7 -37 -16z"/>
<path d="M1672 1334 c-11 -12 -8 -14 15 -14 15 0 24 4 18 8 -5 4 -12 10 -15
14 -3 3 -11 0 -18 -8z"/>
<path d="M1772 1343 c12 -3 18 -18 21 -51 l3 -47 2 53 c2 48 0 52 -20 51 -17
-1 -18 -3 -6 -6z"/>
<path d="M1860 1293 c0 -31 3 -53 8 -49 4 4 7 26 7 49 0 37 3 42 22 41 12 0
20 3 17 8 -3 4 -16 8 -30 8 -23 0 -24 -3 -24 -57z"/>
<path d="M2020 1337 c0 -7 -12 -26 -28 -43 -15 -17 -26 -38 -23 -49 3 -16 1
-17 -12 -7 -13 11 -15 10 -10 -8 9 -33 19 -32 33 5 12 30 16 32 37 24 16 -6
22 -5 18 1 -3 6 -13 10 -21 10 -21 0 -17 18 6 30 11 6 20 18 20 27 0 9 3 13 8
9 13 -13 8 -106 -5 -106 -16 0 -17 -46 -2 -56 6 -3 16 -1 22 5 9 9 8 11 -5 6
-13 -5 -18 -2 -18 9 0 9 7 16 15 16 22 0 22 125 -1 134 -24 9 -34 7 -34 -7z"/>
<path d="M2124 1337 c-3 -8 -4 -47 -2 -88 2 -60 6 -74 20 -77 12 -2 17 5 20
25 3 24 9 29 41 34 21 3 43 12 50 19 10 10 9 12 -4 7 -12 -4 -15 0 -11 19 2
13 8 21 13 18 5 -3 9 2 9 10 0 10 -9 16 -24 16 -28 0 -43 16 -24 23 7 3 -9 6
-35 6 -31 1 -49 -4 -53 -12z m28 -37 c2 -28 0 -40 -9 -40 -8 0 -13 14 -13 40
0 22 4 40 9 40 5 0 11 -18 13 -40z m67 11 c23 -15 5 -58 -29 -69 -24 -9 -30
-8 -30 3 0 9 7 12 18 9 16 -5 16 -5 0 6 -18 11 -24 41 -11 53 9 10 35 8 52 -2z"/>
<path d="M2318 1343 c7 -2 12 -22 12 -44 0 -21 4 -39 8 -39 5 0 9 20 10 45 0
40 -2 45 -21 44 -12 0 -16 -3 -9 -6z"/>
<path d="M2478 1328 c3 -12 14 -32 24 -42 18 -20 19 -20 16 4 -3 22 1 25 35
28 58 5 40 26 -25 30 -54 3 -56 2 -50 -20z m101 8 c-10 -8 -89 -13 -89 -5 0 5
21 9 47 9 25 0 44 -2 42 -4z"/>
<path d="M2640 1339 c0 -6 7 -9 15 -6 8 4 17 2 20 -3 6 -9 -8 -14 -27 -11 -5
0 -8 -3 -8 -9 0 -18 39 -10 50 10 10 18 13 19 26 5 20 -20 24 -19 24 5 0 17
-7 20 -50 20 -30 0 -50 -4 -50 -11z"/>
<path d="M2830 1341 c0 -5 10 -8 23 -7 12 1 25 -3 29 -9 5 -6 8 -6 8 3 0 6 8
12 18 12 13 0 14 -2 4 -9 -11 -7 -11 -9 2 -14 11 -5 16 -1 16 12 0 16 -8 19
-50 19 -27 1 -50 -3 -50 -7z"/>
<path d="M3038 1328 c3 -12 7 -17 10 -10 2 7 8 9 14 6 6 -4 9 -1 6 7 -3 8 -12
15 -21 17 -12 2 -14 -2 -9 -20z"/>
<path d="M3174 1341 c3 -5 -8 -11 -24 -15 -17 -3 -30 -9 -30 -14 0 -14 61 -1
67 15 5 12 7 12 14 1 7 -10 9 -10 9 0 0 6 7 12 15 12 8 0 15 -4 15 -10 0 -5
-5 -10 -12 -10 -9 0 -9 -3 0 -12 16 -16 35 3 26 25 -4 12 -18 17 -46 17 -21 0
-37 -4 -34 -9z"/>
<path d="M3322 1313 c1 -37 1 -37 5 -5 5 36 16 41 33 15 11 -17 11 -17 6 0 -3
11 0 17 9 17 8 0 15 -4 15 -10 0 -5 11 -10 23 -10 14 0 24 6 24 15 0 12 -13
15 -59 15 l-58 0 2 -37z"/>
<path d="M2311 1252 c0 -51 3 -81 9 -77 5 3 7 12 4 19 -4 10 2 12 22 8 19 -3
25 -1 20 7 -4 6 -13 11 -21 11 -13 0 -16 10 -28 90 -3 14 -5 -12 -6 -58z"/>
<path d="M2765 1311 c3 -12 2 -21 -4 -21 -5 0 -22 -12 -36 -26 -24 -24 -24
-25 -4 -19 14 5 19 4 15 -4 -10 -15 5 -13 27 3 19 15 23 71 6 82 -8 4 -9 0 -4
-15z m-4 -54 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z"/>
<path d="M1921 1309 c-1 -9 -8 -22 -16 -29 -17 -14 -20 -30 -5 -30 6 0 15 10
20 23 5 12 9 16 9 8 1 -8 3 -12 6 -9 3 3 1 16 -4 29 -6 15 -10 18 -10 8z"/>
<path d="M2962 1300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1610 1286 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3342 1280 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2930 1279 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3363 1264 c0 -16 2 -23 4 -17 3 8 14 11 29 7 19 -5 24 -2 23 12 0
11 -3 14 -5 7 -6 -12 -35 -6 -45 10 -4 5 -6 -4 -6 -19z"/>
<path d="M1642 1267 c-8 -9 -4 -15 16 -25 17 -8 22 -8 15 -1 -6 6 -9 18 -6 25
6 17 -11 18 -25 1z"/>
<path d="M2851 1273 c0 -5 4 -17 9 -28 6 -13 9 -15 9 -5 1 13 2 13 11 0 7 -11
10 -12 10 -2 0 7 -6 20 -14 28 -16 15 -26 18 -25 7z"/>
<path d="M3140 1269 c0 -6 -7 -9 -15 -5 -8 3 -15 2 -15 -3 0 -5 9 -11 20 -14
15 -4 20 0 20 14 0 10 -2 19 -5 19 -3 0 -5 -5 -5 -11z"/>
<path d="M1684 1254 c3 -9 6 -27 6 -41 0 -21 2 -24 15 -13 17 14 20 40 5 40
-5 0 -10 7 -10 15 0 8 -5 15 -11 15 -6 0 -8 -7 -5 -16z"/>
<path d="M2540 1261 c0 -6 9 -9 20 -8 11 1 20 5 20 9 0 4 -9 8 -20 8 -11 0
-20 -4 -20 -9z"/>
<path d="M2510 1249 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2938 1248 c16 -16 15 -28 -3 -28 -8 0 -15 5 -15 12 0 6 -2 9 -5 6
-3 -2 2 -19 11 -36 16 -33 44 -43 44 -16 0 9 -5 12 -12 8 -8 -5 -9 0 -5 15 7
27 -1 51 -16 51 -7 0 -7 -4 1 -12z"/>
<path d="M3310 1245 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2476 1243 c-10 -10 -7 -73 3 -73 6 0 13 5 16 10 3 6 1 10 -4 10 -6
0 -11 4 -11 9 0 5 9 7 20 4 11 -3 20 -3 20 -1 0 12 -38 47 -44 41z"/>
<path d="M1790 1218 c0 -19 -2 -20 -11 -8 -8 12 -9 10 -7 -9 5 -41 28 -38 28
3 0 20 -2 36 -5 36 -3 0 -5 -10 -5 -22z"/>
<path d="M2641 1207 c-1 -31 2 -38 16 -35 9 2 18 9 21 17 3 10 0 12 -11 8 -12
-4 -17 1 -20 21 -3 21 -4 18 -6 -11z"/>
<path d="M3031 1224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3050 1222 c0 -12 -4 -22 -10 -22 -5 0 -10 -7 -10 -16 0 -9 7 -14 18
-12 20 4 29 45 13 61 -8 8 -11 5 -11 -11z"/>
<path d="M1860 1197 c0 -22 4 -28 18 -25 10 2 18 14 20 28 2 20 -2 26 -18 26
-15 0 -20 -7 -20 -29z m30 -2 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5
15 10 15 6 0 10 -7 10 -15z"/>
<path d="M2841 1203 c-9 -10 -10 -18 -2 -26 7 -7 12 -6 17 6 9 25 1 37 -15 20z"/>
<path d="M3167 1208 c2 -5 14 -8 26 -9 12 0 16 -3 9 -6 -18 -7 -4 -23 21 -23
17 0 19 2 7 10 -8 5 -11 12 -7 16 3 4 14 1 23 -7 14 -12 16 -11 13 6 -3 16
-13 19 -51 20 -25 0 -44 -3 -41 -7z"/>
<path d="M3315 1210 c-7 -11 5 -40 17 -40 5 0 6 5 3 10 -3 6 -1 10 4 10 6 0
11 4 11 9 0 13 -28 22 -35 11z"/>
<path d="M1590 1200 c0 -14 48 -32 77 -28 35 5 14 24 -34 32 -27 4 -43 2 -43
-4z m67 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M3140 1201 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3414 1194 c3 -9 6 -18 6 -20 0 -2 4 -4 9 -4 5 0 7 9 4 20 -3 11 -10
20 -15 20 -6 0 -7 -7 -4 -16z"/>
<path d="M2390 1190 c0 -5 7 -6 17 -3 11 4 14 3 9 -5 -4 -7 -2 -12 3 -12 6 0
11 7 11 15 0 8 -9 15 -20 15 -11 0 -20 -4 -20 -10z"/>
<path d="M2524 1189 c-5 -9 1 -10 20 -5 15 4 26 2 26 -4 0 -5 5 -10 10 -10 6
0 10 7 10 15 0 18 -55 21 -66 4z"/>
<path d="M1347 1023 c-14 -13 -7 -33 11 -33 10 0 23 -5 30 -12 9 -9 12 -9 12
-1 0 7 -4 15 -10 18 -21 13 -9 25 23 21 17 -2 42 -4 55 -5 13 0 20 -5 17 -11
-3 -5 -1 -10 4 -10 6 0 11 9 11 20 0 18 -7 20 -73 20 -41 0 -77 -3 -80 -7z"/>
<path d="M1557 1023 c-4 -3 -6 -49 -5 -102 l2 -96 3 85 c4 98 11 126 23 95 7
-17 9 -17 9 -2 1 9 8 17 17 17 13 0 14 -3 4 -15 -6 -8 -10 -20 -7 -27 4 -9 6
-8 6 3 1 12 10 15 41 12 34 -3 40 0 40 17 0 18 -7 20 -63 20 -35 0 -67 -3 -70
-7z"/>
<path d="M1750 1016 c0 -19 20 -32 21 -14 0 7 4 4 9 -7 5 -11 6 -42 3 -70 -3
-27 -2 -47 3 -42 4 4 10 39 12 77 4 70 4 70 -22 70 -16 0 -26 -6 -26 -14z"/>
<path d="M1942 943 l2 -88 5 80 c6 84 20 104 47 68 13 -17 13 -17 14 0 0 9 4
17 10 17 5 0 7 -7 4 -15 -5 -11 1 -15 19 -15 16 0 26 7 30 20 5 19 0 20 -64
20 l-69 0 2 -87z"/>
<path d="M2158 1002 c-33 -32 -35 -48 -9 -61 15 -9 20 -7 24 10 3 11 15 23 26
26 12 3 21 9 21 14 0 12 -17 11 -25 -1 -3 -5 -11 -10 -17 -10 -7 0 -3 8 8 19
14 13 32 18 57 16 21 -2 35 0 32 5 -2 4 -24 8 -47 8 -33 0 -49 -6 -70 -26z
m-1 -44 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M2410 1020 c-11 -8 -8 -9 13 -4 32 7 36 -7 5 -21 -12 -6 -24 -16 -26
-24 -3 -8 12 -2 32 14 19 15 33 31 30 36 -7 12 -35 11 -54 -1z"/>
<path d="M2614 1016 c-3 -8 -4 -25 -1 -38 4 -21 5 -20 12 9 4 18 12 31 18 30
12 -2 33 -31 43 -59 3 -10 10 -18 15 -18 8 0 -2 34 -21 71 -12 22 -58 26 -66
5z"/>
<path d="M2781 989 c-13 -22 -29 -43 -37 -46 -8 -3 -14 -10 -14 -15 0 -6 7 -4
16 3 14 11 15 10 12 -11 -2 -19 -8 -24 -26 -23 -26 3 -28 -6 -6 -33 8 -10 13
-13 9 -6 -3 6 -2 12 4 12 6 0 17 14 26 30 9 17 13 30 10 30 -5 0 23 59 42 89
1 2 9 -2 18 -9 11 -9 15 -33 15 -96 0 -76 -10 -105 -23 -71 -3 7 -6 5 -6 -5
-1 -12 6 -18 19 -18 19 0 20 7 20 105 l0 105 -28 0 c-23 0 -33 -8 -51 -41z"/>
<path d="M3010 926 l0 -106 25 0 c21 0 25 4 24 28 -2 24 -2 24 -6 4 -3 -13
-10 -21 -16 -19 -18 6 -20 90 -3 128 14 34 14 34 15 7 1 -16 -3 -28 -9 -28 -5
0 -10 -5 -10 -11 0 -6 5 -7 13 -2 17 11 66 12 73 2 3 -5 -5 -9 -18 -9 -14 0
-33 -7 -44 -15 -18 -14 -17 -14 16 -9 19 3 40 7 47 9 6 2 17 -2 23 -10 13 -15
50 -27 50 -15 0 5 -13 14 -30 22 -31 13 -39 33 -17 41 6 3 -8 4 -33 3 l-45 -1
-5 40 c-4 32 -9 41 -27 43 -23 3 -23 2 -23 -102z"/>
<path d="M3150 994 c0 -21 4 -33 10 -29 6 3 10 16 10 28 0 20 0 20 15 1 14
-18 14 -18 15 6 0 20 -5 26 -25 28 -22 2 -25 -2 -25 -34z"/>
<path d="M3258 974 c-7 -82 2 -118 31 -122 13 -2 28 2 35 8 8 9 4 11 -19 6
-29 -6 -30 -5 -33 35 -1 22 1 38 6 35 5 -3 9 1 9 9 0 8 -4 12 -9 9 -4 -3 -8
11 -8 30 0 25 5 36 14 36 11 0 16 -14 19 -47 l3 -48 2 53 c2 49 0 52 -22 52
-21 0 -24 -5 -28 -56z"/>
<path d="M3395 1019 c-4 -6 -4 -13 0 -17 3 -3 10 0 15 8 7 11 10 4 11 -25 0
-22 4 -51 9 -65 7 -23 8 -24 15 -6 6 13 4 21 -3 24 -7 2 -12 22 -12 43 0 24 4
38 10 34 6 -3 10 -1 10 4 0 14 -47 14 -55 0z"/>
<path d="M3501 988 c0 -24 4 -52 9 -63 6 -13 7 -5 3 25 -4 35 -1 49 11 59 22
18 33 5 18 -20 -8 -12 -10 -25 -5 -32 5 -7 9 -24 8 -39 -1 -34 8 -36 23 -4 9
19 8 25 -4 30 -17 7 -18 35 0 59 16 21 38 23 30 2 -4 -8 -1 -15 5 -15 6 0 11
6 11 14 0 8 6 17 13 19 6 3 -18 6 -55 6 l-68 1 1 -42z"/>
<path d="M3645 998 c7 -28 -9 -41 -18 -15 -2 6 -6 0 -9 -15 -3 -16 -1 -28 3
-28 5 0 9 5 9 11 0 6 7 9 15 5 21 -8 24 38 3 59 -6 7 -7 1 -3 -17z"/>
<path d="M2271 996 c-17 -9 -20 -15 -11 -21 7 -4 19 0 28 9 21 21 12 27 -17
12z"/>
<path d="M2510 1002 c0 -5 9 -17 20 -27 23 -21 27 -4 4 19 -17 18 -24 20 -24
8z"/>
<path d="M1430 990 c0 -15 21 -23 32 -12 6 6 2 11 -11 15 -12 3 -21 1 -21 -3z"/>
<path d="M2355 973 c-12 -18 -14 -28 -7 -31 7 -2 12 -8 12 -13 0 -6 -4 -7 -10
-4 -13 8 -13 -18 1 -48 12 -26 47 -52 57 -42 4 4 -1 11 -11 16 -9 6 -17 19
-17 30 0 11 -4 17 -10 14 -11 -7 -13 6 -4 29 3 9 10 13 15 10 5 -3 9 0 9 6 0
7 -7 10 -15 6 -9 -3 -15 0 -15 10 0 8 7 17 15 20 8 4 15 10 15 15 0 18 -18 8
-35 -18z"/>
<path d="M1972 965 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1580 920 c0 -35 2 -61 4 -59 8 8 10 108 3 116 -4 3 -7 -22 -7 -57z"/>
<path d="M1755 970 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M2490 976 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M1413 954 c-13 -14 -18 -90 -7 -118 3 -9 15 -16 25 -16 17 0 19 8 19
66 0 46 -3 63 -11 58 -8 -4 -9 -25 -5 -60 3 -30 2 -54 -3 -54 -16 0 -22 50
-11 91 13 46 11 53 -7 33z"/>
<path d="M2631 954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1748 923 c3 -18 7 -21 13 -11 12 18 11 25 -4 31 -10 4 -13 -2 -9
-20z"/>
<path d="M3170 940 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5 0
-17 -5 -25 -10z"/>
<path d="M1646 925 c25 -19 34 -19 34 0 0 10 -10 15 -27 15 -26 0 -26 -1 -7
-15z"/>
<path d="M2000 923 c0 -10 4 -14 8 -8 4 6 11 13 16 18 5 4 2 7 -7 7 -10 0 -17
-8 -17 -17z"/>
<path d="M2050 931 c0 -5 -8 -12 -17 -14 -14 -4 -12 -5 5 -6 13 -1 22 5 22 14
0 8 -2 15 -5 15 -3 0 -5 -4 -5 -9z"/>
<path d="M2118 929 c-4 -9 0 -26 8 -38 8 -12 13 -25 10 -29 -8 -13 61 -42 97
-41 30 1 29 2 -10 11 -52 11 -57 29 -6 21 23 -4 34 -3 30 4 -8 13 -56 14 -79
2 -14 -8 -18 -6 -18 9 0 10 -4 22 -9 28 -5 5 -11 18 -13 29 -3 17 -5 18 -10 4z"/>
<path d="M2510 918 c-1 -34 -21 -54 -57 -53 -17 0 -29 -3 -26 -8 7 -12 65 -4
70 10 6 16 23 17 23 2 0 -20 -33 -38 -70 -39 -26 0 -31 -3 -20 -10 17 -11 55
-4 88 17 18 11 20 17 12 33 -14 26 -13 57 3 63 7 3 5 6 -5 6 -12 1 -18 -7 -18
-21z"/>
<path d="M2610 923 c0 -12 8 -23 20 -26 13 -3 20 0 20 9 0 7 6 11 14 8 8 -3
17 0 22 7 5 9 -1 11 -23 6 -17 -3 -35 -1 -42 6 -8 8 -11 6 -11 -10z"/>
<path d="M2790 933 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M3641 923 c-1 -12 4 -25 9 -28 12 -7 12 2 0 30 -8 19 -9 19 -9 -2z"/>
<path d="M3185 921 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M3595 920 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M2822 890 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3300 909 c0 -5 -4 -7 -10 -4 -5 3 -10 2 -10 -3 0 -6 7 -12 15 -16
10 -4 15 1 15 14 0 11 -2 20 -5 20 -3 0 -5 -5 -5 -11z"/>
<path d="M1972 875 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2160 890 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3382 880 c-7 -11 -20 -20 -29 -20 -13 0 -14 -3 -5 -12 9 -9 16 -6
32 12 21 24 37 22 26 -5 -8 -22 -55 -38 -72 -24 -7 6 -23 9 -36 7 -19 -3 -15
-7 21 -18 40 -13 46 -12 80 7 20 11 37 29 39 39 3 16 1 17 -7 4 -9 -12 -11
-11 -11 8 0 27 -21 29 -38 2z"/>
<path d="M3501 863 l-1 -43 63 2 62 1 -55 6 c-54 6 -55 7 -61 41 l-6 35 -2
-42z"/>
<path d="M3617 880 c-3 -11 -2 -20 3 -20 12 0 21 28 11 35 -5 2 -11 -4 -14
-15z"/>
<path d="M1740 870 c0 -13 5 -18 15 -14 8 4 15 10 15 14 0 4 -7 10 -15 14 -10
4 -15 -1 -15 -14z"/>
<path d="M2396 875 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0 -7
-7 -4 -15z"/>
<path d="M2610 867 c0 -10 8 -17 18 -17 9 0 13 4 7 8 -5 4 -13 11 -17 16 -5 5
-8 2 -8 -7z"/>
<path d="M3540 870 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3643 854 c-12 -27 0 -32 22 -10 11 11 12 16 2 24 -9 8 -15 4 -24
-14z"/>
<path d="M1600 858 c0 -4 21 -8 48 -7 52 1 48 6 -10 11 -21 2 -38 0 -38 -4z"/>
<path d="M1840 860 c0 -5 7 -7 15 -4 9 4 15 0 15 -9 0 -11 -12 -17 -42 -20
-41 -3 -41 -4 10 -5 45 -2 52 1 52 17 0 10 -7 22 -16 25 -23 9 -34 7 -34 -4z"/>
<path d="M2267 864 c7 -3 9 -12 6 -21 -4 -11 -2 -14 8 -11 21 7 17 32 -6 35
-11 2 -15 0 -8 -3z"/>
<path d="M1987 840 c-5 -18 0 -20 44 -20 27 0 49 4 49 10 0 5 -7 7 -15 4 -8
-4 -27 -3 -43 2 -25 7 -24 8 18 14 l45 7 -46 1 c-39 2 -48 -1 -52 -18z"/>
<path d="M2650 844 c0 -11 -6 -15 -20 -11 -11 3 -20 1 -20 -4 0 -5 11 -9 25
-9 18 0 25 5 25 20 0 11 -2 20 -5 20 -3 0 -5 -7 -5 -16z"/>
<path d="M3186 848 c5 -8 0 -9 -14 -6 -15 4 -22 2 -22 -8 0 -14 41 -20 53 -8
8 8 -3 34 -15 34 -5 0 -6 -5 -2 -12z"/>
<path d="M1570 828 c0 -5 27 -8 60 -8 33 0 60 3 60 8 0 4 -27 7 -60 7 -33 0
-60 -3 -60 -7z"/>
<path d="M1745 830 c-4 -6 3 -10 14 -10 25 0 27 6 6 13 -8 4 -17 2 -20 -3z"/>
<path d="M1940 830 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
